<template>
    <div class="wrapper nav-open">
        <side-bar :background-color="sidebarBackground"
                  short-title="Cluji"
                  title="Cluji INSA Lyon">
            <template slot="links">
                <template v-for="link in sidebarLinks">
                    <sidebar-item v-if="link.path" :link="link" @click.native="toggleSidebar"/>
                    <div v-else class="menu-link">
                        <hr class="my-3 w-100" v-if="!link.noTopBorder"/>
                        <h6 class="navbar-heading text-muted">{{ link.name }}</h6>
                    </div>
                </template>
            </template>
        </side-bar>
        <div class="main-content">
            <dashboard-navbar/>
            <dashboard-body-template>
                <router-view/>
            </dashboard-body-template>
        </div>
    </div>
</template>

<script>
    const DashboardNavbar = () => import('@/components/layout/DashboardNavbar');
    const DashboardBodyTemplate = () => import('@/components/templates/DashboardBodyTemplate');

    import auth                 from '@/util/auth';
    import colors               from '@/util/colors';
    import {updateBadgesBureau} from '@/util/badge';
    import {hasSlime}           from '@/util/slime';

    import {mapGetters} from 'vuex';

    export default {
        components: {
            DashboardBodyTemplate,
            DashboardNavbar
        },
        data: () => ({
            $sidebar: null,
            sidebarBackground: 'vue' //vue|blue|orange|green|red|primary
        }),
        computed: {
            ...mapGetters({getBadgeOrNull: ['badge/getBadgeOrNull']}),
            sidebarLinks() {
                let links = [
                    {
                        name: 'Accueil',
                        path: {name: 'dashboard_home'},
                        icon: 'home'
                    },
                    {
                        name: 'Jeux & Livres',
                        path: {name: 'dashboard_list_jeux_livres'},
                        icon: 'chess',
                        color: colors.jeux.background
                    },
                    {
                        name: 'Événements',
                        path: {name: 'dashboard_list_evenements'},
                        icon: 'calendar-day',
                        color: colors.evenements.background
                    },
                    {
                        name: 'Calendrier',
                        path: {name: 'dashboard_calendar'},
                        icon: ['far', 'calendar-alt'],
                        color: colors.calendrier.background
                    },
                    {
                        name: 'Wiki',
                        path: {name: 'dashboard_wiki'},
                        icon: ['far', 'bookmark']
                    }
                ];

                if (auth.isAnyAsso()) {
                    links = links.concat(
                        [
                            {
                                name: 'Emprunts',
                                path: {name: 'dashboard_emprunts'},
                                icon: 'boxes',
                                color: colors.jeux.background
                            }
                        ]
                    );
                }

                if (auth.isCluji()) {
                    links = links.concat(
                        [
                            {
                                name: 'Local',
                                path: {name: 'dashboard_reservations'},
                                icon: 'place-of-worship',
                                color: colors.local.background
                            },
                            // {
                            //     name: 'Citations',
                            //     path: {name: 'dashboard_citations'},
                            //     icon: ['far', 'comment']
                            // }
                        ]
                    );
                }

                if (hasSlime()) {
                    links = links.concat(
                        [
                            {
                                name: 'Slimes',
                                path: {name: 'dashboard_slimes'},
                                icon: 'paw'
                            }
                        ]
                    );
                }

                links = links.concat(
                    [
                        {
                            name: 'Classements',
                            path: {name: 'dashboard_leaderboard'},
                            icon: 'bars'
                        },
                        {
                            name: 'Paramètres',
                            path: {name: 'dashboard_settings'},
                            icon: 'cog'
                        }
                    ]
                );

                if (auth.isBureau()) {
                    links = links.concat(
                        [
                            {
                                title: true,
                                name: 'Administration',
                            },
                            {
                                name: 'Utilisateurs',
                                path: {name: 'admin_utilisateurs'},
                                icon: 'users',
                                color: colors.utilisateurs.background,
                                badge: this.getBadgeOrNull('utilisateurs')
                            },
                            {
                                name: 'Jeux de rôle',
                                path: {name: 'admin_jdrs'},
                                icon: 'scroll',
                                color: colors.jdrs.background
                            },
                            {
                                name: 'Jeux',
                                path: {name: 'admin_jeux'},
                                icon: 'chess',
                                color: colors.jeux.background
                            },
                            {
                                name: 'Livres',
                                path: {name: 'admin_livres'},
                                icon: 'book',
                                color: colors.livres.background
                            },
                            {
                                name: 'Tags',
                                path: {name: 'admin_borrowable_tags'},
                                icon: 'tags',
                                color: colors.jeux.background
                            },
                            {
                                name: 'Emprunts',
                                path: {name: 'admin_emprunts'},
                                icon: 'boxes',
                                color: colors.jeux.background,
                                badge: this.getBadgeOrNull('emprunts')
                            },
                            {
                                name: 'Local',
                                path: {name: 'admin_reservations'},
                                icon: 'place-of-worship',
                                color: colors.local.background,
                                badge: this.getBadgeOrNull('local')
                            },
                            {
                                name: 'Événements',
                                path: {name: 'admin_evenements'},
                                icon: 'calendar-day',
                                color: colors.evenements.background,
                                badge: this.getBadgeOrNull('evenements')
                            },
                            {
                                name: 'Associations',
                                path: {name: 'admin_associations'},
                                icon: 'people-carry'
                            },
                            {
                                name: 'Statistiques',
                                path: {name: 'admin_stats'},
                                icon: ['far', 'chart-bar'],
                                color: colors.utilisateurs.background
                            },
                            {
                                name: 'Permanences',
                                path: {name: 'admin_permanences'},
                                icon: 'cogs',
                                color: colors.permanences.background
                            },
                            {
                                name: 'Mailing list',
                                path: {name: 'admin_mailer'},
                                icon: 'envelope'
                            },
                            {
                                name: 'Boîte à jouets',
                                path: {name: 'admin_jouets'},
                                icon: 'cubes'
                            },
                            {
                                name: 'Archives',
                                path: {name: 'admin_archives'},
                                icon: 'box-open'
                            },
                            {
                                name: 'Webcrétarium',
                                path: {name: 'admin_webcretarium'},
                                icon: 'terminal'
                            }
                        ]
                    )
                }

                return links;
            }
        },
        methods: {
            toggleSidebar() {
                if (this.$sidebar.showSidebar) {
                    this.$sidebar.displaySidebar(false);
                }
            }
        },
        mounted() {
            updateBadgesBureau();
        }
    };
</script>

<style>
    body {
        background: #f8f9fe none;
    }

    .wrapper {
        min-height: calc(100vh - 60px); /* Allows the footer to stay at the bottom of pages, even small ones */
    }

    div.menu-link {
        width: 80%;
        text-align: center;
        margin: auto;
    }
</style>
